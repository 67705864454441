import { z } from "zod";

const hexColorCodeSchema = z
  .string()
  .trim()
  .regex(/^#(?:[\da-f]{3,4}|[\da-f]{6,8})$/iu) // #000000ff, #000000, #000f, #000
  .transform((string) => string.toLocaleLowerCase());

const classLabelSchema = z.object({
  displayName: z.string().trim().min(1).nullish(),
  hexColorCode: hexColorCodeSchema.nullish(),
  id: z.string().trim().min(1),
  parentId: z.string().trim().min(1).nullish(),
});

export type ClassLabelElement = z.infer<typeof classLabelSchema>;

const nameSchema = z.string().trim();

export const AppConfigSchema = z.object({
  apiRefetchInterval: z.number().default(5000),
  apiUrl: z
    .string()
    .trim()
    .default(process.env.NEXT_PUBLIC_API_URL ?? "/api"),
  classLabelList: z.array(classLabelSchema).optional(),
  colorTree: z.record(z.string().trim(), hexColorCodeSchema.nullish()).optional(),
  feedBearingTimeConfigurationNameAndModelNamePresetList: z
    .array(z.tuple([nameSchema, nameSchema.nullable()]))
    .default([]),
  isDefaultConfig: z.boolean().default(false),
  pinnedBearingTimeConfigurationNameList: nameSchema.array().default([]),
  pinnedModelNameList: nameSchema.array().default([]),
});

export type AppConfig = z.infer<typeof AppConfigSchema>;

export const AppConfigDefault = AppConfigSchema.parse({
  isDefaultConfig: true,
});
