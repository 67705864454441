const publicFilePath = process.env.NEXT_PUBLIC_PUBLIC_FILE_PATH ?? "";
const appConfigFileName = process.env.NODE_ENV === "development" ? "example.config.json" : "config.json";
const appConfigFilePath = `${publicFilePath}/${appConfigFileName}`;
const isDockerBuild = process.env.NEXT_PUBLIC_IS_DOCKER_BUILD === "true";

export const environment = {
  appConfigFileName,
  appConfigFilePath,
  isDockerBuild,
  publicFilePath,
};
