import { ReactNode, useCallback, useLayoutEffect, useMemo, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AppSettingsState,
  appSettingsStateDefault,
  appSettingsStateSchema,
  FeedConfiguration,
  feedConfigurationDefaults,
  FeedTargetData,
  UpdateStateValue,
} from "./app-settings";
import { AppSettingsContext } from "./app-settings-context";

export type AppSettingsAction =
  | {
      payload:
        | {
            bearingTimeConfigurationId: string;
            modelId: string | null;
          }
        | { bearingTimeConfigurationName: string; modelName: string | null };
      type: "addFeedConfiguration";
    }
  | {
      payload: {
        feedConfigurationId: string;
        feedConfigurationPatch: Partial<Omit<FeedConfiguration, "id">>;
      };
      type: "updateFeedConfiguration";
    }
  | {
      payload: UpdateStateValue<string[]>;
      type: "hiddenClassLabelIdList";
    }
  | { payload: string; type: "recreateRecentFeedConfiguration" }
  | { payload: string; type: "removeFeedConfiguration" }
  | { payload: UpdateStateValue<boolean>; type: "autoUpdateIsEnabled" }
  | { payload: UpdateStateValue<boolean>; type: "rightColumnIsOpen" }
  | { payload: UpdateStateValue<FeedTargetData>; type: "feedTargetData" };

const isFunction = <Type,>(
  value: Type | ((previousValue: Type) => Type),
): value is (previousValue: Type) => Type => typeof value === "function";

const getValue = <Type,>(value: Type | ((previousValue: Type) => Type), previousValue: Type): Type => {
  if (isFunction(value)) {
    return value(previousValue);
  }
  return value;
};

const appSettingsReducer = (state: AppSettingsState, action: AppSettingsAction): AppSettingsState => {
  switch (action.type) {
    case "addFeedConfiguration": {
      return {
        ...state,
        feedConfigurationList: [
          ...state.feedConfigurationList,
          {
            ...feedConfigurationDefaults,
            ...action.payload,
            id: uuidv4(),
          },
        ],
      };
    }
    case "updateFeedConfiguration": {
      return {
        ...state,
        feedConfigurationList: state.feedConfigurationList.map((feedConfiguration) =>
          feedConfiguration.id === action.payload.feedConfigurationId
            ? { ...feedConfiguration, ...action.payload.feedConfigurationPatch }
            : feedConfiguration,
        ),
      };
    }
    case "removeFeedConfiguration": {
      const removedFeedConfiguration = state.feedConfigurationList.find(({ id }) => id === action.payload);
      return {
        ...state,
        feedConfigurationList: state.feedConfigurationList.filter(({ id }) => id !== action.payload),
        recentFeedConfigurationList:
          removedFeedConfiguration == null
            ? state.recentFeedConfigurationList
            : [removedFeedConfiguration, ...state.recentFeedConfigurationList],
      };
    }
    case "recreateRecentFeedConfiguration": {
      const recentFeedConfiguration = state.recentFeedConfigurationList.find(
        ({ id }) => id === action.payload,
      );
      return {
        ...state,
        feedConfigurationList:
          recentFeedConfiguration == null
            ? state.feedConfigurationList
            : [...state.feedConfigurationList, recentFeedConfiguration],
        recentFeedConfigurationList: state.recentFeedConfigurationList.filter(
          ({ id }) => id !== action.payload,
        ),
      };
    }
    default: {
      return {
        ...state,
        [action.type]: getValue<typeof action.payload>(action.payload, state[action.type]),
      };
    }
  }
};

export const AppSettingsProvider = (properties: { children: ReactNode }) => {
  const { children } = properties;

  let initialState: AppSettingsState = appSettingsStateDefault;
  try {
    const localStorageState = localStorage.getItem("forerunner-app-settings");
    if (localStorageState != null) {
      const parsedPersistedState = appSettingsStateSchema.safeParse(JSON.parse(localStorageState));
      if (parsedPersistedState.success) {
        initialState = parsedPersistedState.data;
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to parse localStorage state", error);
    localStorage.removeItem("forerunner-app-settings");
  }

  const [state, dispatch] = useReducer(appSettingsReducer, initialState);

  useLayoutEffect(() => {
    localStorage.setItem("forerunner-app-settings", JSON.stringify(state));
  }, [state]);

  const addFeedConfiguration = useCallback(
    (
      feedConfigurationKey:
        | {
            bearingTimeConfigurationId: string;
            modelId: string | null;
          }
        | { bearingTimeConfigurationName: string; modelName: string | null },
    ) => {
      dispatch({ payload: feedConfigurationKey, type: "addFeedConfiguration" });
    },
    [],
  );

  const updateFeedConfiguration = useCallback(
    (feedConfigurationId: string, feedConfigurationPatch: Partial<Omit<FeedConfiguration, "id">>) => {
      dispatch({
        payload: { feedConfigurationId, feedConfigurationPatch },
        type: "updateFeedConfiguration",
      });
    },
    [],
  );

  const removeFeedConfiguration = useCallback((feedConfigurationId: string) => {
    dispatch({ payload: feedConfigurationId, type: "removeFeedConfiguration" });
  }, []);

  const recreateRecentFeedConfiguration = useCallback((recentFeedConfigurationId: string) => {
    dispatch({ payload: recentFeedConfigurationId, type: "recreateRecentFeedConfiguration" });
  }, []);

  const setFeedTargetData = useCallback((feedDatetime: UpdateStateValue<FeedTargetData>) => {
    dispatch({ payload: feedDatetime, type: "feedTargetData" });
  }, []);

  const setAutoUpdateIsEnabled = useCallback((autoUpdateIsEnabled: UpdateStateValue<boolean>) => {
    dispatch({ payload: autoUpdateIsEnabled, type: "autoUpdateIsEnabled" });
  }, []);

  const setHiddenClassLabelIdList = useCallback((hiddenClassLabelIdList: UpdateStateValue<string[]>) => {
    dispatch({ payload: hiddenClassLabelIdList, type: "hiddenClassLabelIdList" });
  }, []);

  const setRightColumnIsOpen = useCallback((rightColumnIsOpen: UpdateStateValue<boolean>) => {
    dispatch({ payload: rightColumnIsOpen, type: "rightColumnIsOpen" });
  }, []);

  const value = useMemo(
    () => ({
      addFeedConfiguration,
      recreateRecentFeedConfiguration,
      removeFeedConfiguration,
      setAutoUpdateIsEnabled,
      setFeedTargetData,
      setHiddenClassLabelIdList,
      setRightColumnIsOpen,
      updateFeedConfiguration,
      ...state,
    }),
    [
      addFeedConfiguration,
      removeFeedConfiguration,
      recreateRecentFeedConfiguration,
      setAutoUpdateIsEnabled,
      setFeedTargetData,
      setHiddenClassLabelIdList,
      setRightColumnIsOpen,
      updateFeedConfiguration,
      state,
    ],
  );

  return <AppSettingsContext.Provider value={value}>{children}</AppSettingsContext.Provider>;
};
