import { z } from "zod";

const feedConfigurationSchema = z.object({
  bearingTimeConfigurationId: z.string().nullable(),
  bearingTimeConfigurationName: z.string().nullable(),
  gradientId: z.string(),
  id: z.string(),
  intensityBounds: z.tuple([z.number(), z.number()]),
  modelId: z.string().nullable(),
  modelName: z.string().nullable(),
  shouldDrawBaffleLineLayer: z.boolean(),
  shouldDrawModelLayer: z.boolean(),
  shouldSmoothModelLayer: z.boolean(),
});

export type FeedConfiguration = z.infer<typeof feedConfigurationSchema>;

export const feedConfigurationDefaults = {
  bearingTimeConfigurationId: null,
  bearingTimeConfigurationName: null,
  gradientId: "black-to-green",
  intensityBounds: [0.1, 0.5],
  modelId: null,
  modelName: null,
  shouldDrawBaffleLineLayer: true,
  shouldDrawModelLayer: true,
  shouldSmoothModelLayer: true,
} satisfies Omit<FeedConfiguration, "id">;

const feedTargetDataSchema = z.object({ datetime: z.number(), heading: z.number() });

export type FeedTargetData = z.infer<typeof feedTargetDataSchema>;

export const appSettingsStateSchema = z.object({
  autoUpdateIsEnabled: z.boolean(),
  feedConfigurationList: feedConfigurationSchema.array(),
  feedTargetData: feedTargetDataSchema,
  hiddenClassLabelIdList: z.string().array(),
  recentFeedConfigurationList: feedConfigurationSchema.array(),
  rightColumnIsOpen: z.boolean(),
});

export type AppSettingsState = z.infer<typeof appSettingsStateSchema>;
export type UpdateStateValue<T> = T | ((previousValue: T) => T);

export type AppSettingsSetters = {
  addFeedConfiguration: (
    feedConfigurationKey:
      | {
          bearingTimeConfigurationId: string;
          modelId: string | null;
        }
      | { bearingTimeConfigurationName: string; modelName: string | null },
  ) => void;
  recreateRecentFeedConfiguration: (recentFeedConfigurationId: string) => void;
  removeFeedConfiguration: (feedConfigurationId: string) => void;
  setAutoUpdateIsEnabled: (autoUpdateIsEnabled: UpdateStateValue<boolean>) => void;
  setFeedTargetData: (feedDatetime: UpdateStateValue<FeedTargetData>) => void;
  setHiddenClassLabelIdList: (hiddenClassLabelIdList: UpdateStateValue<string[]>) => void;
  setRightColumnIsOpen: (rightColumnIsOpen: UpdateStateValue<boolean>) => void;
  updateFeedConfiguration: (
    feedConfigurationId: string,
    feedConfigurationPatch: Partial<Omit<FeedConfiguration, "id">>,
  ) => void;
};

export type AppSettings = AppSettingsSetters & AppSettingsState;

export const appSettingsStateDefault = {
  autoUpdateIsEnabled: true,
  feedConfigurationList: [],
  feedTargetData: { datetime: Date.now(), heading: 0 },
  hiddenClassLabelIdList: [],
  recentFeedConfigurationList: [],
  rightColumnIsOpen: true,
} satisfies AppSettingsState;

export const appSettingsSettersDefault: AppSettingsSetters = {
  addFeedConfiguration: () => {
    /* NOOP */
  },
  recreateRecentFeedConfiguration: () => {
    /* NOOP */
  },
  removeFeedConfiguration: () => {
    /* NOOP */
  },
  setAutoUpdateIsEnabled: () => {
    /* NOOP */
  },
  setFeedTargetData: () => {
    /* NOOP */
  },
  setHiddenClassLabelIdList: () => {
    /* NOOP */
  },
  setRightColumnIsOpen: () => {
    /* NOOP */
  },
  updateFeedConfiguration: () => {
    /* NOOP */
  },
};

export const appSettingsDefault: AppSettings = {
  ...appSettingsStateDefault,
  ...appSettingsSettersDefault,
};
