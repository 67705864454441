import { ReactNode, useMemo } from "react";
import { AppInformationContext } from "./app-information-context";

export const AppInformationProvider = (properties: {
  appConfigFilePath: string;
  children: ReactNode;
  isDockerBuild: boolean;
}) => {
  const { appConfigFilePath, children, isDockerBuild } = properties;
  const appInformation = useMemo(
    () => ({ appConfigFilePath, isDockerBuild }),
    [appConfigFilePath, isDockerBuild],
  );

  return <AppInformationContext.Provider value={appInformation}>{children}</AppInformationContext.Provider>;
};
