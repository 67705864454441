import { createContext } from "react";
import { ClassLabel, ClassLabelTree } from "./class-labels";

export type ClassLabelsContextValue = {
  classLabelTrees: Record<string, ClassLabelTree>;
  classLabels: Record<string, ClassLabel>;
  hiddenClassLabelIdList: string[];
  toggleClassLabelVisibility: (classLabelId: string) => void;
};

export const ClassLabelsContext = createContext<ClassLabelsContextValue>({
  classLabels: {},
  classLabelTrees: {
    "": {
      id: "",
      nodeIdList: [],
    },
  },
  hiddenClassLabelIdList: [],
  toggleClassLabelVisibility: () => {
    /* NOOP */
  },
});
