import { createEnvironment } from "@spear-ai/relay-environment";
import React, { useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { useAppConfig } from "../app-config/use-app-config";

export const RelayProvider = (properties: { children: JSX.Element }) => {
  const { children } = properties;
  const { apiUrl } = useAppConfig();
  const environment = useMemo(() => createEnvironment({ apiUrl: `${apiUrl}/graphql` }), [apiUrl]);

  return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>;
};
