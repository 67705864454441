import { ReactNode, useMemo } from "react";
import { useAppConfig } from "../app-config/use-app-config";
import { useAppSettings } from "../app-settings/use-app-settings";
import { ClassLabel, ClassLabelTree } from "./class-labels";
import { ClassLabelsContext } from "./class-labels-context";

export const colorClassLabels = (options: {
  ancestorHexColorCode?: string | null | undefined;
  ancestorIsHidden?: boolean;
  ancestorVisibleHexColorCode?: string | null | undefined;
  classLabelId?: string | undefined;
  classLabelTreePath?: number[];
  classLabelTrees: Record<string, ClassLabelTree>;
  classLabels: Record<string, ClassLabel>;
  colorTree?: Record<string, string | null | undefined> | undefined;
  hiddenClassLabelIdList?: string[];
}) => {
  const {
    ancestorHexColorCode,
    ancestorIsHidden = false,
    ancestorVisibleHexColorCode,
    classLabelId = "",
    classLabelTreePath = [],
    classLabelTrees,
    classLabels,
    colorTree = {},
    hiddenClassLabelIdList = [],
  } = options;

  const classLabelTree = classLabelTrees[classLabelId];
  const classLabel = classLabels[classLabelId];
  const isHidden = hiddenClassLabelIdList.includes(classLabelId);

  if (classLabel != null) {
    classLabel.isHidden = isHidden;
    classLabel.ancestorIsHidden = ancestorIsHidden;
    classLabel.ancestorHexColorCode = ancestorHexColorCode;
    if (classLabel.hexColorCode == null) {
      classLabel.hexColorCode = colorTree[classLabelTreePath.join(".")] ?? null;
    }
    classLabel.visibleHexColorCode =
      isHidden || ancestorIsHidden ? ancestorVisibleHexColorCode : classLabel.hexColorCode;
  }

  if (classLabelTree != null) {
    for (const [index, childClassLabelId] of classLabelTree.nodeIdList.entries()) {
      colorClassLabels({
        ancestorHexColorCode: classLabel?.hexColorCode ?? ancestorHexColorCode,
        ancestorIsHidden: isHidden || ancestorIsHidden,
        ancestorVisibleHexColorCode: classLabel?.visibleHexColorCode,
        classLabelId: childClassLabelId,
        classLabels,
        classLabelTreePath: [...classLabelTreePath, index],
        classLabelTrees,
        colorTree,
        hiddenClassLabelIdList,
      });
    }
  }
};

export const ClassLabelsProvider = (properties: { children: ReactNode }) => {
  const { classLabelList, colorTree } = useAppConfig();
  const { hiddenClassLabelIdList, setHiddenClassLabelIdList } = useAppSettings();
  const { children } = properties;

  const memoizedClassLabelData = useMemo(() => {
    const classLabels: Record<string, ClassLabel> = {};

    const rootClassLabelTree: ClassLabelTree = {
      id: "",
      nodeIdList: [],
    };

    const classLabelTrees: Record<string, ClassLabelTree> = {
      "": rootClassLabelTree,
    };

    if (classLabelList !== undefined) {
      for (const classLabel of classLabelList) {
        const { id, parentId } = classLabel;
        classLabels[id] = {
          ...classLabel,
          ancestorHexColorCode: null,
          ancestorIsHidden: null,
          isHidden: null,
          visibleHexColorCode: null,
        };

        if (classLabelTrees[id] == null) {
          classLabelTrees[id] = { id, nodeIdList: [] };
        }

        if (parentId === null || parentId === undefined) {
          rootClassLabelTree.nodeIdList.push(id);
        } else {
          const parentClassLabelTree = classLabelTrees[parentId];

          if (parentClassLabelTree == null) {
            classLabelTrees[parentId] = { id: parentId, nodeIdList: [id] };
            rootClassLabelTree.nodeIdList.push(parentId);
          } else {
            parentClassLabelTree.nodeIdList.push(id);
          }
        }
      }
    }

    colorClassLabels({
      classLabels,
      classLabelTrees,
      colorTree,
      hiddenClassLabelIdList,
    });

    return {
      classLabels,
      classLabelTrees,
    };
  }, [colorTree, classLabelList, hiddenClassLabelIdList]);

  const classLabelsContextValue = useMemo(() => {
    const toggleClassLabelVisibility = (classLabelId: string) => {
      setHiddenClassLabelIdList((currentlyHiddenClassLabelIdList) =>
        currentlyHiddenClassLabelIdList.includes(classLabelId)
          ? currentlyHiddenClassLabelIdList.filter(
              (hiddenClassLabelId) => hiddenClassLabelId !== classLabelId,
            )
          : [...currentlyHiddenClassLabelIdList, classLabelId],
      );
    };
    return {
      ...memoizedClassLabelData,
      hiddenClassLabelIdList,
      toggleClassLabelVisibility,
    };
  }, [memoizedClassLabelData, hiddenClassLabelIdList, setHiddenClassLabelIdList]);

  return (
    <ClassLabelsContext.Provider value={classLabelsContextValue}>{children}</ClassLabelsContext.Provider>
  );
};
