"use client";

import { Provider as RadixDirectionProvider } from "@radix-ui/react-direction";
import { Provider as RadixTooltipProvider } from "@radix-ui/react-tooltip";
import { useRouter } from "next/navigation";
import { ThemeProvider } from "next-themes";
import { ReactNode } from "react";
import { I18nProvider, RouterProvider } from "react-aria-components";
import { IntlProvider } from "react-intl";
import { AppConfigProvider } from "@/components/app-config/app-config-provider";
import { AppInformationProvider } from "@/components/app-information/app-information-provider";
import { AppSettingsProvider } from "@/components/app-settings/app-settings-provider";
import { RelayProvider } from "@/components/relay-provider/relay-provider";
import { environment } from "@/environment/environment";
import { messagesByLocale } from "@/messages/messages";
import { ClassLabelsProvider } from "../components/class-labels/class-labels-provider";

export const AppProviders = (properties: {
  children: ReactNode;
  locale: string;
  localeDirection: "ltr" | "rtl";
}) => {
  const { children, locale, localeDirection } = properties;
  const messages = messagesByLocale[locale as keyof typeof messagesByLocale];
  const router = useRouter();

  return (
    <IntlProvider locale={locale} messages={messages}>
      <I18nProvider locale={locale}>
        <RelayProvider>
          <ThemeProvider attribute="class" defaultTheme="dark">
            <RouterProvider
              navigate={(...arguments_) => {
                router.push(...arguments_);
              }}
            >
              <RadixDirectionProvider dir={localeDirection}>
                <RadixTooltipProvider>
                  <AppInformationProvider
                    appConfigFilePath={environment.appConfigFilePath}
                    isDockerBuild={environment.isDockerBuild}
                  >
                    <AppConfigProvider appConfigFileName={environment.appConfigFileName}>
                      <AppSettingsProvider>
                        <ClassLabelsProvider>{children}</ClassLabelsProvider>
                      </AppSettingsProvider>
                    </AppConfigProvider>
                  </AppInformationProvider>
                </RadixTooltipProvider>
              </RadixDirectionProvider>
            </RouterProvider>
          </ThemeProvider>
        </RelayProvider>
      </I18nProvider>
    </IntlProvider>
  );
};
