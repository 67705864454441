/* eslint-disable no-console */
import { ReactNode, useCallback, useEffect, useState } from "react";
import { AppConfig, AppConfigDefault, AppConfigSchema } from "./app-config";
import { AppConfigContext } from "./app-config-context";

export const AppConfigProvider = (properties: { appConfigFileName: string; children: ReactNode }) => {
  const { appConfigFileName, children } = properties;
  const [appConfig, setAppConfig] = useState<AppConfig | null>(null);

  const loadDefaultAppConfig = useCallback(() => {
    console.info("Falling back to default app config");
    setAppConfig(AppConfigDefault);
  }, [setAppConfig]);

  useEffect(() => {
    const url = new URL(`/${appConfigFileName}`, window.location.origin).href;
    const fetchConfig = async () => {
      const response = await fetch(url);

      if (!response.ok) {
        loadDefaultAppConfig();
        return;
      }

      let responseBody: unknown;

      try {
        responseBody = await response.json();
      } catch (parsingError) {
        // In development this can be caused by React Router serving a NotFound page
        console.error(`“${url}”:`, parsingError);
        loadDefaultAppConfig();
        return;
      }

      const parsedResponseBody = AppConfigSchema.safeParse(responseBody);

      if (!parsedResponseBody.success) {
        console.error(`“${url}”:`, parsedResponseBody.error.format());
        loadDefaultAppConfig();
        return;
      }

      setAppConfig(parsedResponseBody.data);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchConfig();
  }, [appConfigFileName, loadDefaultAppConfig, setAppConfig]);

  return appConfig == null ? null : (
    <AppConfigContext.Provider value={appConfig}>{children}</AppConfigContext.Provider>
  );
};
